import axios from 'axios'
import CONFIG from '../config'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { addError } from '../store/modules/modal'
import ERRORS from '../errors/messages'
import store from '../store'
import qs from 'querystring'
import Cookie from 'js-cookie'

const PAIRING_ENDPOINT = `${CONFIG.linking.base_url}/auth/O2/`;
export const client = axios.create({
  baseURL: PAIRING_ENDPOINT,
  headers: {
    'Accept-Language': CONFIG.default_language_header,
    'Content-Type': 'application/x-www-form-urlencoded',
    'X-Amz-Music-Client-Name': CONFIG.client.name,
  }
})

export const getCode = () => {
  return client.post('create/codepair', qs.stringify({
    client_id: process.env.REACT_APP_AMAZON_CODE_CLIENT_ID,
    scope: 'amazon_music:access'}))   // Empty payload forces axios to send content type header
    .then(({data}) => data)                   // See https://github.com/axios/axios/issues/86
    .catch(err => {
      if (err.response) {
        const { status, data, statusText } = err.response;
        store.dispatch(addError({
          ref: data.error,
          report: {
            brief: statusText,
            explanation: 'Unable to retrieve codepair. Please restart app.',
            options: []
          },
          source: status
        }, true))
      } else if (err.message === 'Network Error') {
        store.dispatch(addError(ERRORS.network, true))
      }
    })
}

export const storeAuthData = (data) => {
  return new Promise((resolve, reject) => {
    const expires = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    Cookie.set('amzn_music_auth', data, { expires })
    resolve(data)
  })
}

export const getAuthData = () => {
  return new Promise((resolve, reject) => {
    const data = Cookie.getJSON('amzn_music_auth')
    resolve(data)
  })
}

export const deleteAuthData = () => {
  return new Promise((resolve) => {
    const data = Cookie.remove('amzn_music_auth')
    resolve(data)
  })
}

class Poller {
  rate = 1
  // NOTE: not sure if we need/use this
  cancel() {
    try {
      this.reject({
        error: 'canceled',
        error_description: 'polling has been canceled'
      })
    } catch (_) { }
    this.donePolling()
  }

  donePolling () {
    clearTimeout(this.pollingEmmitter)
    delete this.pollrequest
    this.rate = 1
    this.slowingDown = true
  }

  poll () {
    const { device_code, interval, user_code } = this.payload
    return client.post('token', qs.stringify({
      device_code,
      user_code,
      grant_type: 'device_code'
    }))
      .then(({ data }) => {
        this.resolve(data)
        this.donePolling()
        return 'resolved'
      })
      .catch(({ response: { data } }) => {
        switch (data.error) {
          case 'slow_down': // Need to figure out how to manage this.
            this.rate++
            // falls through
          case 'authorization_pending':
            let delay = 10000
            if (this.slowingDown === true) {
              delay = this.rate * interval * 1000
            }

            this.pollingEmmitter = setTimeout(() => {
              this.poll()
            }, delay)
            break
          default:
            // invalid_code_pair
            // invalid_client
            // unauthorized_client
            console.error(`Error with polling, cancel and (maybe) restart error: ${data.error}`)
            this.donePolling()
            store.dispatch(addError({
              ref: 'pairing_unsuccessful',
              report: {
                brief: 'Pairing Unsuccessful',
                explanation: 'The code pair has not been authorized.',
                options: [{ action: () => this.reject(data), label: 'GET NEW CODE', resume: false, uri: null }]
              },
              source: 'Pairing Unsuccessful'
            }, true))
            break
        }
      })
  }

  getPollResult (payload) {
    return new Promise((resolve, reject) => {
      this.reject = reject
      this.resolve = resolve
      this.payload = payload
      // See results and info at
      // https://developer.amazon.com/docs/alexa-voice-service/code-based-linking-other-platforms.html
      if (this.pollrequest) return this.pollrequest // Don't stack responses.
      this.pollrequest = this.poll()
      // slow down poller after x milliseconds
      setTimeout(() => {
        this.slowingDown = true
      }, 1000 * 30)
    })
  }
}

const poller = new Poller()
export const pollForCode = (config) => {
  return poller.getPollResult(config)
}

const refresh = (refresh_token) => {
  return client.post(`${PAIRING_ENDPOINT}token`, qs.stringify({
    refresh_token,
    grant_type: 'refresh_token'
  }), {
    transformRequest: [(data, headers) => {
      delete headers.common.Authorization
      return data
    }],
  })
    .then(response => response.data)
    .catch(error => error)
}

export const refreshToken = (refresh_token) => {
  const wait = 100;
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      refresh(refresh_token)
        .then(resolve)
        .catch(reject)
    }, wait)
  })
}

const authorizeWrapper = connectedRouterRedirect({
  redirectPath: '/linking',
  authenticatedSelector: state => {
    return state.auth.access_token !== null
  },
  wrapperDisplayName: 'UserIsAuthenticated'
})
export const authWrapper =(c) => authorizeWrapper(c)

const locationHelper = locationHelperBuilder({})
export const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false,
  authenticatedSelector: state => state.auth.access_token === null,
  wrapperDisplayName: 'UserIsNotAuthenticated'
})
